/*====================
    About Area 
======================*/

.about-inner {
    @media #{$md-layout} {
        padding-top: 40px;
    }
    @media #{$sm-layout} {
        padding-top: 40px;
    }
    .section-title{
        h2{
            &.title{
                margin-bottom: 8px;
            }
        }
        p{
            &.description{
                font-weight: 300;
                a{
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }

    }
}

.about-wrapper {
    .thumbnail {
        position: relative;
        z-index: 2;
        img{
            border-radius: 20px;
           
        }
    }
}


.about-us-list{
    margin-top: 15px;
    h3{
        &.title{
            font-size: 30px;
            @extend %fontWeight500;
            margin-bottom: 20px;
            @media #{$sm-layout} {
                font-size: 26px;
                margin-bottom: 8px;
            }
        }
    }
    p{
        font-weight: 300;
    }
}

.rn-content-box-style--1 {
    .content{
        text-align: left;
        padding: 0 120px;
        @media #{$md-layout} {
            padding: 0 60px;
        }
        @media #{$sm-layout} {
            padding: 0 40px;
        }
        p {
            &.subtitle {
                color: $heading-color;
                font-size: 16px;
                font-weight: 400;
                background: rgba(255,26,80,0.03);
                padding: 8px 25px;
                display: inline-block;
                border-radius: 4px;
                span {
                    color: #f9004d;
                }
            }
        }
        h2 {
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 36px;
            line-height: 50px;
            font-weight: 500;
            @media #{$lg-layout} {
                margin-top: 7px;
            }
            @media #{$md-layout} {
                margin-top: 7px;
            }
            @media #{$sm-layout} {
                margin-top: 7px;
            }
        }
        p {
            font-size: 18px;
            line-height: 30px;
        }
    }
}