/*====================
    Breadcrump Area 
======================*/


.react-selectrix .rs-toggle {
    color: black !important;
    font-size: 16px !important;
}

.react-selectrix .rs-header {
    color: black !important;
    font-size: 16px !important;
    background: none !important;
}


.header-wrapper .react-selectrix .rs-header {
    border: none !important;
}


@media (min-width: 1200px){
    .container {
        max-width: 1260px;
    }
}

.panel-open {
    font-size: 1rem;
  }

.breadcrumb-inner {
    text-align: center;
    h2{
        &.title{
            color: white;
        }
    }
    ul{
        &.page-list {
            @extend %liststyle;
            li{
                display: inline-block;
                color: #fff;
                a{
                    color: #fff;
                }
                &.breadcrumb-item{
                    &.active{
                        color: #f10;
                    }
                }
            }
        }
    }
}


.breadcrupm-style--2{
    h2 {
        &.title {
            text-transform: uppercase;
            font-size: 72px;
            @media #{$sm-layout} {
                font-size: 40px;
                line-height: 59px;
            }
        }
    }
    span {
        font-size: 22px;
        color: #c6c9d8;
    }
}

.rn-page-title {
    h2 { 
        &.title {
            font-size: 72px;
            @media #{$lg-layout} {
                font-size: 56px;
            }
            @media #{$md-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                font-size: 38px;
                line-height: 59px;
            }
        }
    }
    p {
        font-size: 22px;
        line-height: 27px;
        color: #c6c9d8;
    }
}





.backto-top {
    > div {
        z-index: 999;
        width: 50px;
        height: 50px;
        line-height: 49px;
        border-radius: 50%;
        background-color: #ffffff;
        text-align: center;
        overflow: hidden;
        z-index: 999 !important;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    }
    svg {
        font-size: 14px !important;
        color: #222222;
        width: 27px;
        height: 27px;
    }
}





// .pricing .rs-option {
//     padding: 2px !important;
// }



// .rn-pricing .pricing-table-inner .pricing-header .pricing span {
//     display: contents !important;
// }

