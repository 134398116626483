/*-------------------------------
    Pricing Area  
--------------------------------*/

.rn-pricing {
    border: 1px solid slateblue;
    border-radius: 5px;
    transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
    transform-style: preserve-3d;
    position: relative;
    z-index: 2;

    &::before {
        z-index: -1;
        display: inline-block;
        content: '';
        -webkit-transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
        transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
        opacity: 0;
        border-radius: 5px;
        background-color: slateblue;
        background-image: linear-gradient(to right, slateblue, #ef0963);
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
    }
    .pricing-table-inner{
        padding: 15px;
        .pricing-header {
            margin-bottom: 30px;
            padding-bottom: 30px;
            text-align: center;
            border-bottom: 1px solid rgba(248, 31, 1, 0.1);
            h4 {
                &.title {
                    margin-bottom: 30px;
                }
            }
            .pricing {
                span {
                    display: block;
                    &.price {
                        font-size: 100px;
                        color:slateblue;
                        line-height: 1;
                    }
                    &.subtitle {
                        font-size: 14px;
                        color: $theme-color;
                    }
                }
            }
        }
        .pricing-body {
            text-align: center;
            margin-bottom: 48px;
            ul {
                &.list-style--1 {

                }
            }
        }
        .pricing-footer {
           
            text-align: center;
            a {
                &.rn-btn {
                    @extend %transition;
                }
            }
        }
    }

  

}










