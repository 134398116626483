
ul.list-dojopy > li {
    font-size: 16px !important;
    padding: 5px !important;
}

.numberCircle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding-top: 1px;

    border: 2px solid rgb(115, 111, 111);
    color: rgb(115, 111, 111);
    text-align: center;
    font-size: 16px;
}


.modal-content {
    border-radius: 20px;
}

.grow {
    box-shadow: #424241 6px 5px 15px 0px;
    transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.05); }


@media screen and (max-width: 365px) {
    .image_temario {
        max-width: 305px;
    }
    
}

.body-evento {
    padding: 31px;
    padding-top: 5px !important;
}

.phone-modal {
    padding-left: 5px !important;
}

.title-evento {
    margin-bottom: 3px !important;
}


@media screen and (max-width: 450px) {
    .body-evento {
        padding: 20px;
        padding-top: 5px !important;
    }
    
}


.btn_evento:hover {
    color: white !important;
}

.title-gradient {
    font-size: 79px;
    background:linear-gradient(135deg, #fad961 0%,#f76b1c 100%);
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media #{$sm-layout} {
        font-size: 49px !important;
    }
}

@media screen and (max-width: 450px) {
    .title-gradient {
        font-size: 39px;
    }
}



.title-gradient-funnel {
    font-size: 70px;
    background:linear-gradient(135deg, #fad961 0%,#f76b1c 100%);
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media #{$sm-layout} {
        font-size: 33px !important;
    }
}

@media screen and (max-width: 450px) {
    .title-gradient {
        font-size: 39px;
    }
}



.title-gradient-2 {
    font-size: 79px;
    background: linear-gradient(to right, #8e2de2, #4a00e0);
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media #{$sm-layout} {
        font-size: 49px !important;
    }
}

@media screen and (max-width: 450px) {
    .title-gradient-2 {
        font-size: 39px;
    }
}


@media screen and (max-width: 360px) {
    .form-wrapper {
        max-width: 320px !important;
    }
}


.blog-h1 {
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    font-size: 55px;
    color: white;
}

.blog-h2 {
    font-family: 'Varela Round', sans-serif;
    font-weight: 700;
    font-size: 33px;
    color: #1d1d24;
    margin-top: 10px;
}

.blog-img {
    border-radius: 20px;
    height: 250px;
    margin-bottom: 20px; 
}

li {
    font-size: 17px;    
}

.blog-resume {
    font-weight: bold;
    font-style: italic;
}

.my-tipical {
    font-family: 'Varela Round', sans-serif;
    color: white;
    font-size: 36px;

    @media #{$sm-layout} {
        font-size: 21px;
    }

    @media #{$md-layout} {
        font-size: 25px !important;
    }

}


.h1-dojopy {
    font-weight: 900;
    font-size: 85px !important;
    line-height: 90px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    text-align: left;
    display: block !important;

    @media #{$sm-layout} {
        line-height: 57px;
        font-size: 47px !important;
    }

    @media #{$md-layout} {
        font-size: 50px !important;
        margin-top: -20px !important;
    }

}

body > div.fade.modal.show > div > div > div.modal-body {
    align-self: center;
}


[data-tf-widget="qmLY5KOf"] {
    height: 700px !important;
}



.dojofoter {

    @media #{$md-layout} {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

}

.modal-dojopy {
    @media #{$md-layout} {
        max-width: 300px !important;
    }
}

#emailDojo, #dojoPhone {
    @media #{$sm-layout} {
        font-size: 18px !important;
    } 
    @media #{$md-layout} {
        font-size: 18px !important;
    } 
}


.calendly-overlay {
    z-index: 999999999 !important;
}


.react-selectrix .rs-arrow-wrapper {
    width: 20px ;
}
.planOptions .rs-header {
    padding: 8px;
}


.cuotas-inscription .rs-header {
    height: 40px !important;
    align-items: center;
    align-content: center !important;
}

.pais-inscription {
    text-align: center;
    min-width: 110px !important;
}

.wasap-form {
    text-align: center;
    min-width: 77px !important;
}


#modal-card:hover, #modal-deposito:hover, #modal-paypal:hover, #modal-yape:hover {
    box-shadow: rgba(68, 38, 135, 0.47) 4px 4px 16px 1px !important;
    cursor: pointer;
}

.play-buton-zoom {
    border: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1040;
    max-width: 250px;
    transition: zoom 1s;

    @media (max-width: "500px") {
        top: 45%;
    }
}

.pause-buton-zoom {
    border: none !important;
    position: absolute;
    right: 11px;
    bottom: 5px;
    z-index: 1040;
    max-width: 250px;

    @media (max-width: "500px") {
        right: 10px;
        bottom: 5px;    
    }
}


.zoom-buton-zoom {
    border: none !important;
    position: absolute;
    left: 15px;
    bottom: 5px;
    z-index: 1040;
    max-width: 80px;
    padding: 5px !important;
    border-radius: 25px !important;

    @media (max-width: "500px") {
        left: 10px;
        bottom: 5px;
    }
}


.vistas-buton-zoom {
    border: none !important;
    position: absolute;
    bottom: -30px;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 1040;
    max-width: 80px;
    padding: 10px !important;
    border-radius: 20px !important;


    @media (max-width: "500px") {
        bottom: -40px;
        left: 45%;
    }
}

.buton-zoom {
    color: white;
}
.buton-zoom:hover {
    color: white !important;
    zoom: 105%;
}



.texto-especial {
    @media (min-width: "1200px") {
        p {
            font-size: 20px !important;
        }
    }

}

.texto-btn {
    position: relative;
    top: 2px;
}

.btn-efect-ads {
    background-color: purple;
    border: none;
    color: white;
    font-size: 24px;
    padding: 16px 32px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    border-radius: 12px;
}

.btn-efect-ads:hover {
    background-color: rgba(128, 0, 128, 0.593);
}


.dojo-mural {
    // height: 450px;
    background-color: #1d1716;
    background-image: linear-gradient(rgba(255, 87, 87, 0.2), rgba(140, 82, 255, 0.5)), url("/assets/images/dojopy/mural-evento.webp");
    background-size: cover;
    background-repeat: no-repeat;

    // @media #{$sm-layout} {
    //     height: 500px;
    // }
    
}

.title-mural {
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 25px;

    @media #{$sm-layout} {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;    
    }
}

.dojo-h1 {
    padding-top: 7px;
    max-width: 940px;
    color: white;
    font-family: 'Poppins',sans-serif;
    font-size: 50px;
    @media #{$sm-layout} {
        font-size: 37px;
    }
}

.dojo-h2 {
    margin: 5px;
    font-weight: 500;
    color: white;
    font-size: 23px;
    @media #{$sm-layout} {
        font-size: 18px;
    }
}

.live-zoom {
    margin-left: 5px;
    margin-top: 15px;
    height: 40px;
    display: inline-block;
    @media #{$sm-layout} {
        height: 30px;
    }
}

.btn-grupo-ws {
    font-size: 23px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #25d366	;
    color: white;
    font-weight: bold;
}

.btn-grupo-ws:hover {
    background-color: #0c893a	;
}


.counter-dojo .flip-countdown-title {
    color: #1d1716;
    font-size: 15px !important;
}


#modal-info > .modal-content {
    background: linear-gradient(to right, #8e2de2, #4a00e0)
}

.subtitle-info {
    line-height: 35px !important;
}


#dojo-mural {
    position: absolute;
    left: 0;
    height: 400px;
    width: 100%;
    background-image: linear-gradient(rgba(255, 87, 87, 0.2), rgba(140, 82, 255, 0.5)), url("/assets/images/dojopy/mural-evento.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rebeccapurple;
}

.dojo-matrix {
    position: relative;
    left: 0;
    z-index: 99999999;
}

.btn-efect {
    background-color: #1f1f25 !important;
}

.pulsar {
    display: inline-block;
    width: 12px; /* Ancho de la esfera */
    height: 12px; /* Altura de la esfera */
    background-color: white; /* Color de la esfera */
    border-radius: 50%; /* Hace que sea redonda */
    animation: pulso 1.5s infinite; /* Aplicar la animación */
}

@keyframes pulso {
    0%, 100% {
        transform: scale(1); /* Escala normal */
        opacity: 1; /* Opacidad normal */
    }
    50% {
        transform: scale(1.45); /* Escala aumentada */
        opacity: 0.5; /* Disminuye la opacidad */
    }
}



#counter-deadline {
    background-color: tomato;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999;
    width: 100%;
}


#iframe-video {
    border: none; /* Elimina el borde del iframe */
    overflow: hidden; /* Previene que se muestre el scroll */
    display: block; /* Asegura que se renderice como bloque */
}



#root > div.modal-video > div > div > div > iframe {
    border-radius: 8px;
}

.modal-video-movie-wrap {
    background-color: transparent !important;
    border-radius: 8px;
}

.flip-countdown-piece {
    margin-right: 10px !important;
}